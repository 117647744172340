const govRouter = [
    {
        path: '/gov/threeService/threeServiceList/threeServiceList',
        meta: {
            keepAlive: true
        },
        component: () => import('@/views/gov/threeService/threeServiceList')
    },
    {
        path: '/gov/threeService/threeService/insertThreeService',
        component: () => import('@/views/gov/threeService/insertThreeService')
    },
    {
        path: '/gov/threeService/threeService/comment',
        component: () => import('@/views/gov/threeService/comment')
    },
    {
        path: '/gov/threeService/threeServiceAudit/threeServiceAudit',
        component: () => import('@/views/gov/threeService/threeServiceAudit')
    },
    {
        path: '/gov/threeService/threeServiceAudit/threeServiceDetail',
        component: () => import('@/views/gov/threeService/threeServiceDetail')
    },


    {
        path: '/gov/affairs_street/category/category',
        component: () => import('@/views/gov/affairs_street/category')
    },
    {
        path: '/gov/affairs_street/content/content',
        meta: {
            keepAlive: true
        },
        component: () => import('@/views/gov/affairs_street/content')
    },
    {
        path: '/gov/affairs_street/content/insertContent',
        component: () => import('@/views/gov/affairs_street/insert-content')
    },
    {
        path: '/gov/affairs_street/content/contentChat',
        component: () => import('@/views/gov/affairs_street/content-chat')
    },
    {
        path: '/gov/affairs_street/contentAudit/contentAudit',
        component: () => import('@/views/gov/affairs_street/contentAudit')
    },

    {
        path: '/gov/affairs/category/category',
        component: () => import('@/views/gov/affairs/category')
    },
    {
        path: '/gov/affairs/content/content',
        meta: {
            keepAlive: true
        },
        component: () => import('@/views/gov/affairs/content')
    },
    {
        path: '/gov/affairs/content/insertContent',
        component: () => import('@/views/gov/affairs/insert-content')
    },
    {
        path: '/gov/affairs/content/contentChat',
        component: () => import('@/views/gov/affairs/content-chat')
    },
    {
        path: '/gov/affairs/contentAudit/contentAudit',
        component: () => import('@/views/gov/affairs/contentAudit')
    },

    {
        path: '/gov/affairs_street/contentAudit/detail',
        component: () => import('@/views/gov/affairs_street/audit-detail')
    },
    {
        path: '/gov/org/define/define',
        component: () => import('@/views/gov/org/define')
    },
    {
        path: '/gov/org/baseinfo/baseinfo',
        meta: {
            keepAlive: true
        },
        component: () => import('@/views/gov/org/baseinfo')
    },
    {
        path: '/gov/org/baseinfo/insertBaseinfo',
        component: () => import('@/views/gov/org/insertBaseinfo')
    },
    {
        path: '/gov/org/orgStaff',
        component: () => import('@/views/gov/org/orgStaff')
    },
    {
        path: '/gov/grid/grid',
        meta: {
            keepAlive: true
        },
        component: () => import('@/views/gov/grid/grid')
    },
    {
        path: '/gov/grid/gridInsert',
        component: () => import('@/views/gov/grid/grid-insert')
    },
    {
        path: '/gov/grid/building',
        component: () => import('@/views/gov/grid/building')
    },
    {
        path: '/gov/group/groupDefine',
        meta: {
            keepAlive: true
        },
        component: () => import('@/views/gov/group/groupDefine')
    },
    {
        path: '/gov/group/groupInsert',
        component: () => import('@/views/gov/group/group-insert')
    },
    {
        path: '/gov/group/groupStaffDz',
        component: () => import('@/views/gov/group/groupStaffDz')
    },
    {
        path: '/gov/group/groupStaffYh',
        component: () => import('@/views/gov/group/groupStaffYh')
    },
    {
        path: '/dd/orgDefined',
        component: () => import('@/views/gov/supervision/org-user')
    },
    {
        path: '/group/council',
        component: () => import('@/views/gov/council/list')
    },
    {
        path: '/council/give-record',
        component: () => import('@/views/gov/council/give-record')
    },
    {
        path: '/gov/package/define',
        meta: {
            keepAlive: true
        },
        component: () => import('@/views/gov/package/define')
    },
    {
        path: '/gov/package/insertDefine',
        component: () => import('@/views/gov/package/insertDefine')
    },
    {
        path: '/gov/package/package-family',
        component: () => import('@/views/gov/package/package-family')
    },
    {
        path: '/gov/package/points',
        component: () => import('@/views/gov/package/manageScoreStatistics')
    },
    {
        path: '/gov/package/declare',
        component: () => import('@/views/gov/package/manageApplyStatistics')
    },
    {
        path: '/gov/package/family',
        component: () => import('@/views/gov/package/pack-family-static')
    },
    {
        path: '/gov/package/user',
        component: () => import('@/views/gov/package/package-user-static')
    },
    {
        path: '/gov/package/region',
        component: () => import('@/views/gov/package/package-region-static')
    },
    {
        path: '/gov/package/familyed',
        component: () => import('@/views/gov/package/package-familyed-static')
    },
    {
        path: '/gov/package/comprehensive',
        component: () => import('@/views/gov/package/package-comprehensive-static')
    },{
        path: '/gov/package/package-familys',
        component: () => import('@/views/gov/package/package-familys')
    }

]

export default govRouter