 const appRouter = [
    {
        path: '/app/themePicture/platPicture',
        meta: {
            keepAlive: true
        },
        component: () => import('@/views/app/themePicture/platPicture')
    },
    {
        path: '/app/themePicture/communityPicture',
        meta: {
            keepAlive: true
        },
        component: () => import('@/views/app/themePicture/communityPicture')
    },
     {
         path: '/app/themePicture/insertPicture',
         component: () => import('@/views/app/themePicture/insertPicture')
     },
     {
         path: '/app/themePicture/insertPlatPicture',
         component: () => import('@/views/app/themePicture/insertPlatPicture')
     },
    {
        path: '/app/basicConfigStreet/basicConfigStreet',
        meta: {
            keepAlive: true
        },
        component: () => import('@/views/app/basicConfigStreet/basicConfigStreet')
    },
    {
        path: '/app/basicConfigStreet/basicConfigStreetInsert',
        component: () => import('@/views/app/basicConfigStreet/basicConfigStreetInsert')
    },
    {
        path: '/app/defineList/defineList',
        meta: {
            keepAlive: true
        },
        component: () => import('@/views/app/defineList/defineList')
    },
    {
        path: '/app/defineList/insertDefine',
        component: () => import('@/views/app/defineList/insertDefine')
    },
    {
        path: '/app/regionSet/regionSet',
        meta: {
            keepAlive: true
        },
        component: () => import('@/views/app/defineList/regionSet')
    },
    {
         path: '/app/regionSet/insertRegionSet',
         component: () => import('@/views/app/defineList/insertRegionSet')
    },
     {
         path: '/app/regionSet/insertByRegion',
         component: () => import('@/views/app/defineList/insertByRegion')
     },
    {
        path: '/app/scoreConfig/scoreConfig',
        meta: {
            keepAlive: true
        },
        component: () => import('@/views/app/scoreConfig/scoreConfig')
    },
   {
         path: '/app/scoreConfig/insertScoreConfig',
         component: () => import('@/views/app/scoreConfig/insertScoreConfig')
   },
    {
        path: '/app/scoreStreet/scoreStreet',
        meta: {
            keepAlive: true
        },
        component: () => import('@/views/app/scoreConfig/scoreStreet')
    },
     {
         path: '/app/scoreStreet/insertScoreStreet',
         component: () => import('@/views/app/scoreConfig/insertScoreStreet')
     },
    {
        path: '/app/govern/ruleDefine',
        meta: {
            keepAlive: true
        },
        component: () => import('@/views/app/govern/ruleDefine')
    },
     {
         path: '/app/outh/giveScoreDetailList',
         meta: {
             keepAlive: true
         },
         component: () => import('@/views/app/govern/giveScoreDetailList')
     },
     {
         path: '/app/outh/vetoList',
         meta: {
             keepAlive: true
         },
         component: () => import('@/views/app/govern/vetoList')
     },
     {
         path: '/app/outh/refuseRule',
         meta: {
             keepAlive: true
         },
         component: () => import('@/views/app/govern/refuseRule')
     },
     {
         path: '/app/outh/insertRefuseRule',
         component: () => import('@/views/app/govern/insertRefuseRule')
     },
     {
         path: '/app/outh/giveScoreDetail',
         component: () => import('@/views/app/govern/giveScoreDetail')
     },
     {
         path: '/app/govern/copyRuleDefine',
         component: () => import('@/views/app/govern/copyRuleDefine')
     },
     {
        path: '/app/govern/copyRuleDefines',
        component: () => import('@/views/app/govern/copyRuleDefines')
    },
    {
        path: '/app/govern/ruleType',
        meta: {
            keepAlive: true
        },
        component: () => import('@/views/app/govern/ruleType')
    },
    {
        path: '/app/govern/insertRuleType',
        component: () => import('@/views/app/govern/insertRuleType')
    },
    {
        path: '/app/govern/ruleCode',
        component: () => import('@/views/app/govern/ruleCode')
    },
    {
         path: '/app/govern/sbpz',
         component: () => import('@/views/app/govern/sbpz')
    },
     {
         path: '/app/govern/insertSbpz',
         component: () => import('@/views/app/govern/insertSbpz')
     },
    {
        path: '/app/govern/ruleConstitution',
        meta: {
            keepAlive: true
        },
        component: () => import('@/views/app/govern/ruleConstitution')
    },
    {
        path: '/app/neighbor/neighborContent',
        component: () => import('@/views/app/neighbor/neighborContent')
    },
     {
         path: '/app/neighbor/comment',
         component: () => import('@/views/app/neighbor/comment')
     },
    {
        path: '/app/neighbor/neighborBlacklist',
        component: () => import('@/views/app/neighbor/neighborBlacklist')
    },
    {
        path: '/app/neighbor/neighborDefine',
        meta: {
            keepAlive: true
        },
        component: () => import('@/views/app/neighbor/neighborDefine')
    },
    {
         path: '/app/neighbor/insertNeighborDefine',
         component: () => import('@/views/app/neighbor/insertNeighborDefine')
    },
    {
        path: '/app/study/studyPolicyType',
        component: () => import('@/views/app/study/studyPolicyType')
    },
    {
        path: '/app/study/studyPolicyPassContent',
        component: () => import('@/views/app/study/studyPolicyPassContent')
    },
    {
        path: '/app/study/studyPolicyContent',
        meta: {
            keepAlive: true
        },
        component: () => import('@/views/app/study/studyPolicyContent')
    },
     {
         path: '/app/study/insertPolicyContent',
         component: () => import('@/views/app/study/insertPolicyContent')
     },
    {
        path: '/app/study/studyAnswerDefine',
        meta: {
            keepAlive: true
        },
        component: () => import('@/views/app/study/studyAnswerDefine')
    },
    {
         path: '/app/study/insertAnswerDefine',
         component: () => import('@/views/app/study/insertAnswerDefine')
    },
    {
        path: '/app/study/studyAnswerType',
        component: () => import('@/views/app/study/studyAnswerType')
    },
    {
        path: '/app/study/studyAnswerAsk',
        meta: {
            keepAlive: true
        },
        component: () => import('@/views/app/study/studyAnswerAsk')
    },
    {
        path: '/app/study/insertAnswerAsk',
        component: () => import('@/views/app/study/insertAnswerAsk')
    },
    {
        path: '/app/photograph/photographType',
        component: () => import('@/views/app/photograph/photographType')
    },
     {
         path: '/app/photograph/list',
         meta: {
             keepAlive: true
         },
         component: () => import('@/views/app/photograph/photographList')
     },
     {
         path: '/app/photograph/photographDetail',
         component: () => import('@/views/app/photograph/photographDetail')
     },

     {
        path: '/app/volunteer/station/list',
        meta: {
            keepAlive: true
        },
        component: () => import('@/views/app/volunteer/station/stationList')
    },
    {
         path: '/app/volunteer/station/type',
         component: () => import('@/views/app/volunteer/station/type')
    },
    {
        path: '/app/volunteer/station/Detail',
        component: () => import('@/views/app/volunteer/station/stationDetail')
    },

     {
         path: '/app/photograph/photographCommit',
         component: () => import('@/views/app/photograph/photographCommit')
     },
     {
         path: '/app/publicOpinion/publicOpinionType',
         component: () => import('@/views/app/publicOpinion/publicOpinionType')
     },
     {
         path: '/app/publicOpinion/publicOpinionList',
         meta: {
             keepAlive: true
         },
         component: () => import('@/views/app/publicOpinion/publicOpinionList')
     },
     {
         path: '/app/publicOpinion/publicOpinionCommit',
         component: () => import('@/views/app/publicOpinion/publicOpinionCommit')
     },
     {
         path: '/app/govern/insertRuleType',
         component: () => import('@/views/app/govern/insertRuleType')
     },
     {
         path: '/app/govern/insertRuleDefine',
         component: () => import('@/views/app/govern/insertRuleDefine')
     },
     {
         path: '/app/govern/insertRuleConstitution',
         component: () => import('@/views/app/govern/insertRuleConstitution')
     },
     {
         path: '/app/govern/vetoDetail',
         component: () => import('@/views/app/govern/vetoDetail')
     },
     {
         path: '/app/govern/apply',
         meta: {
             keepAlive: true
         },
         component: () => import('@/views/app/govern/apply')
     },
     {
         path: '/app/govern/applyDetail',
         component: () => import('@/views/app/govern/apply-detail')
     },
     {
         path: '/app/score/redYellow',
         meta: {
             keepAlive: true
         },
         component: () => import('@/views/app/govern/redYellow')
     },
     {
         path: '/app/score/insertRedYellow',
         component: () => import('@/views/app/govern/insertRedYellow')
     },
     {
         path: '/app/village/cate',
         meta: {
             keepAlive: true
         },
         component: () => import('@/views/app/village/cate')
     },
     {
         path: '/app/village/catInsert',
         component: () => import('@/views/app/village/catInsert')
     },
     {
         path: '/app/village/community',
         meta: {
             keepAlive: true
         },
         component: () => import('@/views/app/village/community')
     },
     {
         path: '/app/village/communityInsert',
         component: () => import('@/views/app/village/communityInsert')
     },
     {
         path: '/app/village/content',
         meta: {
             keepAlive: true
         },
         component: () => import('@/views/app/village/content')
     },
     {
         path: '/app/village/village_comment',
         component: () => import('@/views/app/village/village_comment')
     },
     {
         path: '/app/village/contentAudit',
         meta: {
             keepAlive: true
         },
         component: () => import('@/views/app/village/contentAudit')
     },
     {
         path: '/app/village/contentInsert',
         component: () => import('@/views/app/village/contentInsert')
     },
     {
         path: '/app/village/council',
         meta: {
             keepAlive: true
         },
         component: () => import('@/views/app/village/council')
     },
     {
         path: '/app/village/councilInsert',
         component: () => import('@/views/app/village/councilInsert')
     },
     {
         path: '/app/village/mail',
         meta: {
             keepAlive: true
         },
         component: () => import('@/views/app/village/mail')
     },
     {
         path: '/app/village/mailDetail',
         component: () => import('@/views/app/village/mailDetail')
     },
     {
         path: '/app/village/mail_comment',
         component: () => import('@/views/app/village/mail_comment')
     },
     {
         path: '/app/visits/zfjhcx',
         meta: {
             keepAlive: true
         },
         component: () => import('@/views/app/visits/zfjhcx')
     },
     {
         path: '/app/visits/play-detail',
         component: () => import('@/views/app/visits/play-detail')
     },
     {
         path: '/app/visits/jfpz',
         component: () => import('@/views/app/visits/jfpz')
     },
     {
         path: '/app/visits/jfpz-insert',
         component: () => import('@/views/app/visits/jfpz-insert')
     },
]

 export default appRouter